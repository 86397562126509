<template>
  <div class="contact-container">
    <h1 class="site-title">Contact</h1>
    <breadcrumb />
    <div class="contact-card white_card_background">
      <h2>Questions? Comments? Concerns? Something Else?</h2>
      <p>Shoot an email to <a href="mailto:wiffleleagues@gmail.com">wiffleleagues@gmail.com</a> and we'll try getting back to you in a timely manner!</p>
    </div>
  </div>
</template>

<script src="./contact.ts"></script>
<style src="./contact.scss" lang="scss"></style>