export default {
  Login: 'login',
  SignUp: 'signup',
  Home: 'home',
  Schedules: 'schedules',
  Contact: 'contact',
  Players: 'players',
  Player: 'player',
  LeaguePlayer: 'league-player',
  Leagues: 'leagues',
  League: 'league',
  MyLeagues: 'my-leagues',
  Rules: 'rules',
  CreateLeague: 'create-league',
  StartLeague: 'start-league',
  GameSummary: 'game-summary',
  Games: 'games',
  NotFound: 'not-found',
  Profile: 'profile',
  Notifications: 'notifications'
}